<div class="container mt-5">
  <div *ngIf="authService.decodedToken">
    Welcome to TimeSink!
  </div><br/>
  
  <div class="justify-content-center">
    TimeSink allows logging in with social media (Google and Facebook). If you currently log in with 
    an email and password, you should first go to your Member profile to link with social media.<br />
    <b>If your Member profile is not linked, logging in with social media will create a new account!</b><br/><br/>
    Feedback to <a href="mailto:administrator@wyssweb.com">TimeSink Administrator</a>
  </div>
</div>