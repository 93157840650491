import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule, TabsModule, BsDatepickerModule } from 'ngx-bootstrap';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { BlockUIModule } from 'ng-block-ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AuthService } from './_services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './authentication/register/register.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { AlertifyService } from './_services/alertify.service';
import { appRoutes } from './routes';
import { AuthGuard } from './_guards/auth.guard';
import { MemberService } from './_services/member.service';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { TicketListResolver } from './_resolvers/ticket-list.resolver';
import { TimePrintResolver } from './_resolvers/time-print.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { PhotoEditorComponent } from './members/photo-editor/photo-editor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordComponent } from './authentication/password/password.component';
import { LoginComponent } from './authentication/login/login.component';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material.module';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DatePipe } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { TicketListComponent } from './ticket-list/ticket-listcomponent';
import { ProjectComponent } from './project/project.component';
import { ProjectListResolver } from './_resolvers/project-list.resolver';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { TimesheetPrintComponent } from './timesheet/timesheetPrint/timesheetPrint.component';
import { TimeblockComponent } from './timesheet/timeblock/timeblock.component';
import { TicketComponent } from './ticket-list/ticket/ticket.component';

export function tokenGetter() {
   return localStorage.getItem('token');
}
@NgModule({
   declarations: [			
      AppComponent,
      NavComponent,
      HomeComponent,
      MemberEditComponent,
      RegisterComponent,
      LoginComponent,
      ConfirmComponent,
      PasswordComponent,
      PhotoEditorComponent,
      TicketListComponent,
      ProjectComponent,
      TimesheetComponent,
      TimesheetPrintComponent,
      TimeblockComponent,
      TicketComponent
   ],
   imports: [
      BrowserModule,
      ReactiveFormsModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      SharedModule,
      HttpClientModule,
      BsDropdownModule.forRoot(),
      BsDatepickerModule.forRoot(),
      TimepickerModule.forRoot(),
      TabsModule.forRoot(),
      NgbModule,
      RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
      //NgxGalleryModule,
      JwtModule.forRoot({
         config: {
            tokenGetter,
            whitelistedDomains: ['localhost:5000'],
            blacklistedRoutes: ['localhost:5000/api/auth']
         }
      }),
      BlockUIModule.forRoot(),
      DragDropModule,
      MaterialModule,
      SocialLoginModule,
      BrowserAnimationsModule     
   ],
   providers: [
      AuthService,
      ErrorInterceptorProvider,
      AlertifyService,
      AuthGuard,
      PreventUnsavedChanges,
      MemberService,
      MemberEditResolver,
      TicketListResolver,
      DatePipe,
      ProjectListResolver,
      TimePrintResolver,
      {
         provide: 'SocialAuthServiceConfig',
         useValue: {
           autoLogin: false,
           providers: [
             {
               id: GoogleLoginProvider.PROVIDER_ID,
               provider: new GoogleLoginProvider(
                 '901513166970-tje861p33v958oo2oefiprgalandto9m.apps.googleusercontent.com'
               )
             },
             {
               id: FacebookLoginProvider.PROVIDER_ID,
               provider: new FacebookLoginProvider('175633272520167')
             }
           ]
         } as SocialAuthServiceConfig,
       }

   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
