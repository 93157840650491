import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() { }

  confirm(message: string, okCallback: () => any, title: string = 'Confirm') {
    alertify.confirm(message, (e: any) => {
      if (e) {
        okCallback();
      } else {}
    }).set({title});
  }

  confirmCancel(message: string, callback: (choice: boolean) => any, title: string = 'Confirm') {
    alertify.confirm(title, message, () => { callback(true); }, () => { callback(false); });
  }

  success(message: string, title: string = 'Success') {
    alertify.success(message);
  }

  error(message: string, title: string = 'Error') {
    alertify.error(message);
  }

  warning(message: string, title: string = 'Warning') {
    alertify.warning(message);
  }

  message(message: string, title: string = 'Message') {
    alertify.message(message);
  }
}
