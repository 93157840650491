import { NgModule } from '@angular/core';

import {
  MatButtonModule,
} from '@angular/material/button';

import {  
  MatMenuModule
} from '@angular/material/menu';

import {  
  MatToolbarModule,
} from '@angular/material/toolbar';

import {  
  MatIconModule
} from '@angular/material/icon';

import {  
  MatCardModule
} from '@angular/material/card';

@NgModule({
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule
  ],
  exports: [
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule
  ]
})
export class MaterialModule {}