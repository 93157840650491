<div>
  <div>Show Active: 
    <input type="checkbox" [(ngModel)]="this.showActive" [checked]="true" (change)="getTickets()">
  </div>
  <table class='table' width="100%" style="display: block; overflow: scroll; height: 100%;">
    <thead>
      <tr>
        <th>Project</th>
        <th>Title</th>
        <th>Link</th>
        <th>Hours</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ticket of this.tickets">
        <td (click)="this.editTicket(ticket);">{{ ticket.project.title}} ({{ ticket.project.code}})</td>
        <td (click)="this.editTicket(ticket);">{{ ticket.title }}</td>
        <td *ngIf="!ticket?.linkNumber > '' || !ticket?.project.url > ''" (click)="this.editTicket(ticket);">No Link</td>
        <td *ngIf="ticket?.linkNumber > ''"><a target="_blank" href='{{ ticket.project.url.replace("[linkNumber]", ticket.linkNumber)}}'>{{ ticket.linkNumber }}</a></td>
        <td (click)="this.editTicket(ticket);">{{ ticket.estimatedHours}} / {{ ticket.completedHours }}</td>
      </tr>
      <tr>
        <td colspan="5">
          <button *ngIf="this.showActive" class="fa fa-success btn-block" (click)="this.editTicket(null);">New</button>
        </td>
      </tr>
    </tbody>
  </table>
  
</div>