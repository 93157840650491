import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider'
import { MatCheckboxModule } from '@angular/material/checkbox'

import { SortPipe } from 'src/app/_pipes/sortPipe';
import { FilterPipe } from 'src/app/_pipes/filterPipe';
import { ExcludePipe } from 'src/app/_pipes/excludePipe';
import { NotNullPipe } from 'src/app/_pipes/notNullPipe';
import { FilterWildPipe } from 'src/app/_pipes/filterWildPipe';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  declarations: [SortPipe, FilterPipe, ExcludePipe, NotNullPipe, FilterWildPipe],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    MatSliderModule, MatCheckboxModule,
    FileUploadModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    SortPipe, FilterPipe, ExcludePipe, NotNullPipe,FilterWildPipe,
    MatSliderModule, MatCheckboxModule,
    FileUploadModule
  ]
})
export class SharedModule { }
