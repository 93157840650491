import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Member } from '../_models/member';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(this.baseUrl + 'members');
  }

  getMember(id: number): Observable<Member> {
    return this.http.get<Member>(this.baseUrl + 'members/' + id);
  }

  updateMember(id: number, member: Member) {
    return this.http.put(this.baseUrl + 'members/' + id, member);
  }

  setMainPhoto(memberId: number, photoId: number) {
    return this.http.post(this.baseUrl + 'members/' + memberId + '/photos/' + photoId + '/setMain', {});
  }

  deletePhoto(memberId: number, photoId: number) {
    return this.http.delete(this.baseUrl + 'members/' + memberId + '/photos/' + photoId);
  }
}
