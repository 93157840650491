<block-ui>
  <form *ngIf="!this.loading" [formGroup]="registerForm" (ngSubmit)="register()">
    <div class="form-group">
      <strong>Login Email</strong>: Used to login, and to receive password reset links
      
      <input type="email" class="form-control"
        [ngClass]="{'is-invalid': registerForm.get('email').errors && registerForm.get('email').touched}"
        formControlName="email" placeholder="Email Address">
        <div class="invalid-feedback">Please enter a valid email address</div>
    </div>

    <div class="form-group">
      <strong>Password</strong>: {{this.passwordMinLength}} - {{this.passwordMaxLength}} characters
      <input type="password" class="form-control" 
        [ngClass]="{'is-invalid': registerForm.get('password').errors && registerForm.get('password').touched}"
        formControlName="password" placeholder="Password">
        <div class="invalid-feedback"
          *ngIf="registerForm.get('password').hasError('required')
            && registerForm.get('password').touched">
            Password is required
        </div>
        <div class="invalid-feedback"
          *ngIf="registerForm.get('password').hasError('minlength')
          && registerForm.get('password').touched">
            Password must be at least {{this.passwordMinLength}} characters
        </div>
        <div class="invalid-feedback"
          *ngIf="registerForm.get('password').hasError('maxlength')
          && registerForm.get('password').touched">
            Password cannot exceed [[this.passwordMaxLength]] characters
        </div>
    </div>
    <div class="form-group">
      <strong>Confirm Password</strong>: Renter the password
      <input type="password" class="form-control" 
        [ngClass]="{'is-invalid': registerForm.get('confirmPassword').hasError('required') 
          && registerForm.get('confirmPassword').touched
          || registerForm.get('confirmPassword').touched
          && registerForm.hasError('mismatch')}"
          formControlName="confirmPassword" placeholder="Confirm Password">
      <div class="invalid-feedback"
        *ngIf="registerForm.get('confirmPassword').hasError('required')
        && registerForm.get('confirmPassword').touched">
        Password is required
      </div>
      <div class="invalid-feedback"
        *ngIf="registerForm.hasError('mismatch')
        && registerForm.get('confirmPassword').touched">
        Passwords must match
      </div>
    </div>
    <div class="form-group">
      <strong>Displayed Name</strong>: What will be seen by other members
      <input type="text" class="form-control"
        [ngClass]="{'is-invalid': registerForm.get('knownAs').hasError('required') 
          && registerForm.get('knownAs').touched}"
        formControlName="knownAs" placeholder="Known As">
      <div class="invalid-feedback"
        *ngIf="registerForm.get('knownAs').hasError('required')
          && registerForm.get('knownAs').touched">
        Displayed Name is required
      </div>
    </div>

    <div class="form-group text-center">
      <button class="btn btn-success" type="submit" [disabled]="!registerForm.valid">Register</button>
    </div>
    
  </form>
</block-ui>