<div>
  <table class='table'>
    <thead>
      <tr>
        <th>Id</th>
        <th>Title</th>
        <th>Code</th>
        <th>Active</th>
      </tr>
  </thead>
    <tbody>
      <tr *ngFor="let project of this.projects">
        <td>{{ project.id}}</td>
        <td>{{ project.title}}</td>
        <td>{{ project.code}}</td>
        <td>{{ project.isActive}}</td>
      </tr>
      <tr>
        <td>New</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>