import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from 'src/app/_models/login';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  registerForm: UntypedFormGroup;
  model: Login;
  loading = true;
  returnUrl: string;
  passwordMinLength = 6;
  passwordMaxLength = 30;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private alertify: AlertifyService,
              private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    
    this.createRegisterForm();
    this.loading = false;
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]],
      confirmPassword: ['', Validators.required],
      knownAs: ['', Validators.required],
      key: ['']
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : { mismatch : true};
  }

  register() {
    this.blockUI.start('Submitting Registration');
    this.authService.register(this.registerForm.getRawValue()).subscribe(() => {
      this.loginNewMember();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }

  loginNewMember() {
    const newAccount = {
      email: this.registerForm.get('email').value,
      password: this.registerForm.get('password').value
    };
    this.authService.login(newAccount).subscribe(() => {
      this.router.navigateByUrl(this.returnUrl);
    },
    error => {
        this.alertify.error(error);
    }).add(() => {
      this.blockUI.stop();
    });
  }
}
