<block-ui>
  <div class="container mt-4">
    <div class="row">
      <div class="col-sm-4">
          <h1>
              {{member.knownAs}} - Edit Profile
          </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="card">
          <img class="card-img-top img-thumbnail" src="{{photoUrl || '../../assets/user.png'}}" alt="{{member.knownAs}}">       
          <div class="card-footer">
            
          </div>
        </div>
      </div>
      <div class="col-sm-9">
        <tabset class="member-tabset">
          <tab heading="Edit Profile">
            <div *ngIf="editForm.dirty" class="alert alert-info">
              <strong>Information:</strong>You have made changes.  Any unsaved changes will be lost!
            </div>
            <form #editForm="ngForm" id="editForm" (ngSubmit)="updateMember()">
              <h2>Known As</h2>
              <input type="text" name="knownAs" class="form-control" 
                [(ngModel)]="this.member.knownAs">
              <button [disabled]="!editForm.dirty" form="editForm" class="btn btn-success btn-block">Save Changes</button>
            </form>
          </tab>
          <tab heading="Photos">
              <app-photo-editor [(member)]="member" (notifyMainPhotoChanged)="updateMainPhoto($event)"></app-photo-editor>            
          </tab>
          <tab *ngIf="member.logins.length > 0" heading="Logins">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>My Logins</h2>
                </div>
              </div>
              <div class="row">
                <table>
                  <tr>
                    <th>Email Address</th>
                    <th>Actions</th>
                    <th>Source</th>
                  </tr>
                  <tr *ngIf="this.emailLogin != null">
                    <td>{{ this.emailLogin.email}}</td>
                    <td>
                      <button *ngIf="!emailLogin.isConfirmed" class="fa fa-success" (click)="this.sendLoginConfirmation(emailLogin);">
                        Send Confirmation
                      </button>
                      <button *ngIf="emailLogin.isConfirmed" class="fa fa-success" (click)="this.sendPasswordReset(emailLogin);">
                        Send Reset Link
                      </button>
                    </td>
                    <td>Email Login</td>
                  </tr>
                  <tr *ngIf="googleLogin != null">
                    <td>{{ googleLogin.email }}</td>
                    <td><button *ngIf="member.logins.length > 1" (click)="removeSocialLogin(googleLogin)">Remove</button></td>
                    <td>Google</td>
                  </tr>
                  <tr *ngIf="fbLogin != null">
                    <td>{{ fbLogin.email }}</td>
                    <td><button *ngIf="member.logins.length > 1" (click)="removeSocialLogin(fbLogin)">Remove</button></td>
                    <td>Facebook</td>
                  </tr>
                </table>
                <button *ngIf="googleLogin == null" (click)="signInWithGoogle()">Link to Google Login</button>
                <button *ngIf="fbLogin == null" (click)="signInWithFB()">Link to Facebook Login</button>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</block-ui>