<table border="1">
  <thead>
    <tr>
      <th style="padding:0 15px 0 15px;">Project/Date</th>
      <th *ngFor="let date of this.uniqueDates" style="padding:0 15px 0 15px;">
        {{ this.datePipe.transform(date, 'EEEE (yyyy/MM/dd)') }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let project of this.uniqueProjects; let projectIndex = index;">
      <td style="padding:0 15px 0 15px;">{{ project ? project : "No Project" }}</td>
      <td *ngFor="let date of this.uniqueDates; let dateIndex = index;" style="padding:0 15px 0 15px;">
        {{ this.projectDates[projectIndex][dateIndex] }}
      </td>
    </tr>
  </tbody>
</table>

<div>Total Hours: {{this.totalHours}}</div>
<br/><br/><br/>
<div>Raw data:</div>
<table>
  <thead>
    <tr>
      <th style="padding:0 15px 0 15px;">Date</th>
      <th style="padding:0 15px 0 15px;">Project Code</th>
      <th style="padding:0 15px 0 15px;">Project Title</th>
      <th style="padding:0 15px 0 15px;">Tickets</th>
      <th style="padding:0 15px 0 15px;">Hours</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let timesheet of this.timesheets">
      <td style="padding:0 15px 0 15px;">{{ this.datePipe.transform(timesheet?.dateTime, 'yyyy/MM/dd') }}</td>
      <td style="padding:0 15px 0 15px;">{{ timesheet.project?.code }}</td>
      <td style="padding:0 15px 0 15px;">{{ timesheet.project?.title }}</td>
      <td style="padding:0 15px 0 15px;">
        <div *ngFor="let ticket of timesheet.tickets"><a target="_blank" [routerLink]="['/ticket']">{{ ticket?.title }} {{ ticket?.completedHours }}</a></div>
      </td>
      <td style="padding:0 15px 0 15px;">{{ timesheet.hours }}</td>
    </tr>
  </tbody>
</table>