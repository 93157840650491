import { Component, OnInit, ViewChild, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { TicketService } from 'src/app/_services/ticket.service';
import { Ticket } from 'src/app/_models/ticket';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../_services/project.service';
import { Project } from '../_models/project';
import { TicketForUpdate } from '../_models/ticketForUpdate';
import { AlertifyService } from '../_services/alertify.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketComponent } from './ticket/ticket.component';


@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  public tickets: Ticket[];
  public projects: Project[];

  public newTicket: Ticket = {id: 0, project: null, title:'', linkNumber: '', member: null, estimatedHours: 0, completedHours: 0, isActive: true }
  private ticketForUpdate: TicketForUpdate;
  public showActive: boolean = true;
  
  constructor(private route: ActivatedRoute
    , private modalService: NgbModal
    , private ticketService: TicketService
    , private projectService: ProjectService
    , private alertify: AlertifyService) 
  { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.tickets = data.member;
      console.log(this.tickets);
    });

    this.projectService.getMyProjects(true).subscribe(projects => {
      this.projects = projects;
    }, error => {
      console.log(error);
    });
  }

  deactivateTicket(ticket: Ticket) {
    this.ticketService.activateTicket(ticket.id, false).subscribe(ticket => {
      console.log(ticket.id);
      this.tickets.splice(this.tickets.findIndex(t => t.id === ticket.id), 1);
    }, error => {
      this.alertify.error("Could not deactivate ticket id " + ticket.id) + ' because ' + error;
    })
  }

  activateTicket(ticket: Ticket) {
    this.ticketService.activateTicket(ticket.id, true).subscribe(ticket => {
      console.log(ticket.id);
      this.tickets.splice(this.tickets.findIndex(t => t.id === ticket.id), 1);
    }, error => {
      this.alertify.error("Could not activate ticket id " + ticket.id);
    })
  }

  getTickets() {
    this.ticketService.getTickets(this.showActive).subscribe(tickets => {
      this.tickets = tickets;
    });
  }

  public editTicket(ticket: Ticket) {
    if (ticket != null) {
      this.ticketForUpdate = {
        id: ticket.id,
        projectId: ticket.project?.id,
        title: ticket.title,
        linkNumber: ticket.linkNumber,
        estimatedHours: ticket.estimatedHours,
        isActive: ticket.isActive
      }
    } else {
      this.ticketForUpdate = {
        id: 0,
        projectId: 0,
        title: '',
        linkNumber: '',
        estimatedHours: 0,
        isActive: true
      }
    }
    const modalRef = this.modalService.open(TicketComponent);
    modalRef.componentInstance.ticket = this.ticketForUpdate;
    modalRef.componentInstance.projects = this.projects;
    modalRef.result.then((updatedTicket: Ticket) => {
      console.log(updatedTicket);
      location.reload();
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }
}
