import { Injectable } from "@angular/core";
import { TimeSheet } from "../_models/timesheet";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { TimeBlockService } from "../_services/timeBlock.service";
import { AlertifyService } from "../_services/alertify.service";
import { AuthService } from "../_services/auth.service";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class TimePrintResolver implements Resolve<TimeSheet[]> {
    constructor(private timeblockService: TimeBlockService,
                private authService: AuthService,
                private router: Router,
                private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<TimeSheet[]> {
        return this.timeblockService.printTimeSheet(route.queryParams.date, route.queryParams.count).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving your data');
                this.router.navigate(['home']);
                return of(null);
            })
        );
    }
}