import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ticket } from 'src/app/_models/ticket';
import { TimeBlock } from 'src/app/_models/timeBlock';
import { DatePipe } from '@angular/common';
import { TimeBlockForUpdate } from 'src/app/_models/timeBlockForUpdate';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { TimeBlockService } from 'src/app/_services/timeBlock.service';

@Component({
  selector: 'app-timeblock',
  templateUrl: './timeblock.component.html',
  styleUrls: ['./timeblock.component.scss']
})
export class TimeblockComponent implements OnInit {
  @Input() public currentDate: Date;
  @Input() public timeblock: TimeBlockForUpdate;
  @Input() public tickets: Ticket[];

  public editTimeblock: TimeBlockForUpdate;
  public modalTitle: string;
  myForm: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal,
    private alertify: AlertifyService,
    private fb: UntypedFormBuilder, 
    private timeblockService: TimeBlockService, 
    public datePipe: DatePipe) { }

  ngOnInit() {
    this.modalTitle = (this.timeblock.id > 0) ? 'Edit' : 'Create';
    this.editTimeblock = {
      id: this.timeblock.id,
      ticketId: this.timeblock.ticketId,
      notes: this.timeblock.notes,
      startTime: this.datePipe.transform(this.timeblock?.startTime, 'yyyy-MM-dd HH:mm'),
      endTime: this.datePipe.transform(this.timeblock?.endTime, 'yyyy-MM-dd HH:mm')
    }

    this.myForm = this.fb.group({
      start: [this.editTimeblock.startTime, Validators.required],
      end: [this.editTimeblock.endTime, Validators.required],
      ticket: this.editTimeblock.ticketId,
      notes: this.editTimeblock.notes,
    });
  }

  onSubmit(form: UntypedFormGroup) {
    if (form.valid) {
      this.editTimeblock.startTime = this.datePipe.transform(form.value.start, 'yyyy-MM-dd HH:mm');
      this.editTimeblock.endTime = this.datePipe.transform(form.value.end, 'yyyy-MM-dd HH:mm');
      this.editTimeblock.ticketId = form.value.ticket;
      this.editTimeblock.notes = form.value.notes;

      this.timeblockService.updateTimeBlock(this.editTimeblock).subscribe(result => {
        this.activeModal.close(result.timeblock);
      });
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  delete() {
    this.timeblockService.deleteTimeBlock(this.editTimeblock.id).subscribe(result => {
      this.activeModal.close(this.timeblock);
    }, error => {
      this.alertify.error('Could not delete TimeBlock ' + this.editTimeblock.id);
    });

  }
}
