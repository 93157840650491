<nav class="navbar navbar-expand-md navbar-dark {{this.backgroundColor}}">
  <div class="container-fluid">
      
      <div class="dropdown" dropdown>
        <a class="dropdown-toggle text-light" dropdownToggle><span class="mr-2">Menu</span></a>
      
        <div class="dropdown-menu mt-3" *dropdownMenu>
          <!--Example of a link to an external site-->
          <!-- <a class="dropdown-item" href="https://odysseyhunt.com/"><i class="fa fa-map-marker">Odyssey Hunt</i></a>
          <div class="dropdown-divider"></div> -->

          <span *ngIf="this.authService.loggedIn()">
              <a *ngIf="!this.authService.isConfirmed()" class="dropdown-item" (click)="waitingForConfirmation()">
                <i class="fa fa-group">Click here to confirm your account</i>
              </a>
              <!-- Example of a link to a secured internal page/app -->
              <a *ngIf="this.authService.isConfirmed()" class="dropdown-item" [routerLink]="['/project']">
                <i class="fa fa-group">Projects</i>
              </a>
              <a *ngIf="this.authService.isConfirmed()" class="dropdown-item" [routerLink]="['/ticket-list']">
                <i class="fa fa-group">Tickets</i>
              </a>
            <div class="dropdown-divider"></div>
            <a *ngIf="this.authService.isConfirmed()" class="dropdown-item" [routerLink]="['/timesheet']">
              <i class="fa fa-group">Timesheet</i>
            </a>
          </span>

          <!-- Example of a link to a public internal page/app -->
          <!-- <a class="dropdown-item" [routerLink]="['/games']"><i class="fa fa-list">Game List</i></a> -->
        </div>
      </div>
      
      <a class="navbar-brand" [routerLink]="['/']"><i class="fa fa-home">TimeSink</i></a>
  
      <div *ngIf="loggedIn()" class="dropdown" dropdown>
        <a class="dropdown-toggle text-light" dropdownToggle>
            <img draggable="false" src="{{photoUrl || '../../assets/user.png'}}" alt="Profile">
            {{this.authService.currentMember.knownAs}}
        </a>
      
        <div class="dropdown-menu" *dropdownMenu>
          <a class="dropdown-item" [routerLink]="['/member/edit/' + this.authService.currentMember.id]">
            <i class="fa fa-user"></i>Edit Profile</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logOut()"><i class="fa fa-sign-out"></i>Logout</a>
        </div>
      </div>  
      <button *ngIf="!loggedIn()" class="btn btn-success my-2 my-sm-0" type="button" (click)="login();">Login</button>

  </div>
</nav>