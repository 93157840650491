import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../_models/project';
import { ProjectService } from '../_services/project.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  projects: Project[];

  constructor(private route: ActivatedRoute, private projectService: ProjectService) { }

  ngOnInit() {
    console.log('Get Subscription/Projects');
    this.route.data.subscribe(data => {
      this.projects = data.member;
      console.log(this.projects);
    });
  }
}
