import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimeSheet } from 'src/app/_models/timesheet';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { TimeBlockService } from 'src/app/_services/timeBlock.service';

@Component({
  selector: 'app-timesheetPrint',
  templateUrl: './timesheetPrint.component.html',
  styleUrls: ['./timesheetPrint.component.scss']
})
export class TimesheetPrintComponent implements OnInit {

  public printDate: Date;
  public dateCount: number;
  public totalHours: number;

  public timesheets: TimeSheet[];
  public projectDates: any[];

  public uniqueDates: any[];
  public uniqueProjects: any[];

  constructor(private route: ActivatedRoute
    , private alertify: AlertifyService
    , public datePipe: DatePipe) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data) {
        this.timesheets = data.timesheets;
        this.getUniqueDates();
        this.getUniqueProjectCodes();
        this.generateTimeCard();
        this.appendListOfProjectTitles();
      }
    });
  }

  getUniqueDates() {
    var uniqueDates = [];
    for(let i = 0; i< this.timesheets.length; i++){    
        if(uniqueDates.indexOf(this.timesheets[i].dateTime) === -1){
          uniqueDates.push(this.timesheets[i].dateTime);        
        }        
    }
    this.uniqueDates = uniqueDates;
  }

  getUniqueProjectCodes() {
    var uniqueProjs = [];
    for(let i = 0; i< this.timesheets.length; i++){  
      let projectCode = this.timesheets[i].project?.code == null? '': this.timesheets[i].project?.code;
      let existingProjectCode = uniqueProjs.find(up => up == projectCode);
      if (existingProjectCode == null) {
        uniqueProjs.push(projectCode);        
      }
    }
    this.uniqueProjects = uniqueProjs;
  }

  generateTimeCard() {
    this.totalHours = 0;
    let self = this;

    this.projectDates = new Array(this.uniqueProjects.length);
    for (var tc = 0; tc < this.uniqueProjects.length; tc ++) {
      this.projectDates[tc] = new Array(this.uniqueDates.length);
    }

    for (let dateIndex = 0; dateIndex < this.uniqueDates.length; dateIndex++) {
      for (let projectIndex = 0; projectIndex < this.uniqueProjects.length; projectIndex++) {
        let entries = this.timesheets.filter(timesheet => timesheet.dateTime === this.uniqueDates[dateIndex] 
          && (timesheet.project?.code == this.uniqueProjects[projectIndex]
            || timesheet.project == null && this.uniqueProjects[projectIndex] == '')
        );

        let projectDateHours = 0.0;
        entries.forEach(entry => {
          this.totalHours += entry.hours;
          projectDateHours += entry.hours;
        });
        
        this.projectDates[projectIndex][dateIndex] = projectDateHours;      
      }
    }
  }

  appendListOfProjectTitles() {
    let self = this;
    this.uniqueProjects.forEach(function(projectCode, index) {
      let timesheetsUsingProjectCode = self.timesheets.filter(ts => ts.project?.code == projectCode);
      var timesheetProjectTitles = timesheetsUsingProjectCode.map(timesheet => {
        return timesheet?.project?.title;
      });

      var uniqueTitles = timesheetProjectTitles.filter((value, index, array) => array.indexOf(value) === index);
      //if (projectCode != null) {
        self.uniqueProjects[index] = projectCode + " | " + uniqueTitles;
      //}
    });
  }
}
