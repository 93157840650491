import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { TicketService } from '../_services/ticket.service';
import { TimeBlockService } from '../_services/timeBlock.service';
import { TimeBlock } from 'src/app/_models/timeBlock';
import { Ticket } from 'src/app/_models/ticket';
import { DatePipe } from '@angular/common';
import { AlertifyService } from '../_services/alertify.service';
import { formatDate } from '@angular/common';
import { TimeBlockForUpdate } from '../_models/timeBlockForUpdate';
import { TimeblockComponent } from './timeblock/timeblock.component';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {
  
  public totalHours: number = 0;
  public timeblocks: TimeBlock[];
  public tickets: Ticket[] ;
  public timeBlock: TimeBlock = {id: 0, ticket: null, startTime: null, endTime: null, notes: '', completedHours: 0 };
  public currentDate: Date;
  public nextDate: Date;
  time: Date = new Date();

  private timeBlockForUpdate: TimeBlockForUpdate;

  constructor(private route: ActivatedRoute
    , private modalService: NgbModal
    , private ticketService: TicketService
    , private timeblockService: TimeBlockService
    , private alertify: AlertifyService
    , public datePipe: DatePipe) 
  { }

  ngOnInit() {
    var param = this.route.snapshot.queryParamMap.get('date');
    if (param) {
      this.currentDate = new Date(param + ' 00:00:00');
      this.nextDate = new Date(param + ' 00:00:00');
    } else {
      this.currentDate = new Date();
      this.currentDate.setMinutes(0);
      this.nextDate = new Date();
      this.nextDate.setMinutes(0);
    }

    this.nextDate = new Date(this.nextDate.setDate(this.nextDate.getDate() + 1));

    this.timeBlock.startTime = this.currentDate;
    this.timeBlock.endTime = this.currentDate;


    this.timeblockService.getTimeBlocks(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd')
      , this.datePipe.transform(this.nextDate, 'yyyy-MM-dd'))
      .subscribe(timeBlocks => {
        this.timeblocks = timeBlocks;
        this.timeblocks.forEach(timeblock => {
          this.totalHours += timeblock.completedHours;
        });
      }, error => {
        this.alertify.error("Error retrieving your timesheet");
      });

    this.ticketService.getTickets(true).subscribe(result => {
      this.tickets = result;
    }, error=> {
      this.alertify.error("Error retrieving your tickets");
    });
  }

  deleteTimeBlock(timeBlockId: number) {
    this.timeblockService.deleteTimeBlock(timeBlockId).subscribe(result => {
      location.reload();
    }, error => {
      this.alertify.error('Could not delete TimeBlock ' + timeBlockId);
    });
  }

  public editTimeBlock(timeblock: TimeBlock) {
    console.log('editTimeblock');
    if (timeblock != null) {
      this.timeBlockForUpdate = {
        id: timeblock.id,
        ticketId: timeblock.ticket?.id,
        notes: timeblock.notes,
        startTime: this.datePipe.transform(timeblock?.startTime, 'yyyy-MM-dd HH:mm'),
        endTime: this.datePipe.transform(timeblock?.endTime, 'yyyy-MM-dd HH:mm')
      }
    } else {
      console.log(this.currentDate);
      console.log(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd HH') + ':00');
      this.timeBlockForUpdate = {
        id: 0,
        ticketId: null,
        notes: '',
        startTime: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd HH') + ':00',
        endTime: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd HH') + ':00'
      }
    }
    const modalRef = this.modalService.open(TimeblockComponent);
    modalRef.componentInstance.currentDate = this.currentDate;
    modalRef.componentInstance.timeblock = this.timeBlockForUpdate;
    modalRef.componentInstance.tickets = this.tickets;
    modalRef.result.then((updatedTimeblock: TimeBlock) => {
      console.log(updatedTimeblock);
      location.reload();
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }

  updateTimeBlock(timeBlock: TimeBlock) {
    this.timeBlockForUpdate = {
      id: this.timeBlock.id,
      ticketId: this.timeBlock.ticket?.id,
      notes: this.timeBlock.notes,
      startTime: this.datePipe.transform(this.timeBlock?.startTime, 'yyyy-MM-dd HH:mm'),
      endTime: this.datePipe.transform(this.timeBlock?.endTime, 'yyyy-MM-dd HH:mm')
    }
    console.log(this.timeBlockForUpdate);
    this.timeblockService.updateTimeBlock(this.timeBlockForUpdate).subscribe(result => {
      location.reload();
    }, error => {
      this.alertify.error("Could not create new TimeBlock");
    })
  }

  getLink(ticket: Ticket) {
    //console.log(ticket.linkNumber);
    if (ticket === null || ticket.linkNumber === null) return '';
    // console.log("ticket" + ticket);
    // console.log("ticket.linkNumber:" + ticket.linkNumber + '!');
    var result = ticket.project.url.replace('[linkNumber]', ticket.linkNumber);
    //console.log(result + ticket.linkNumber);
    return result;
  }

}
