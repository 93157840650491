
<div> {{ this.currentDate.toDateString() }}</div>
<div>
  <table class='table' width="100%">
    <thead>
      <tr>
        <th>Start</th>
        <th>End</th>
        <th>Ticket</th>
        <th>Notes</th>
      </tr>
  </thead>
    <tbody>
      <tr *ngFor="let timeblock of this.timeblocks">
        <td (click)="this.editTimeBlock(timeblock);">{{ this.datePipe.transform(timeblock.startTime, 'hh:mm a')}}</td>
        <td (click)="this.editTimeBlock(timeblock);">{{ this.datePipe.transform(timeblock.endTime, 'hh:mm a')}}</td>
        <td class="text-break" *ngIf="timeblock.ticket?.linkNumber > ''">
          <a target="_blank" href="{{this.getLink(timeblock.ticket)}}">{{ timeblock.ticket?.title }}</a>
        </td>
        <td class="text-break" *ngIf="!timeblock.ticket?.linkNumber > ''" (click)="this.editTimeBlock(timeblock);">
          {{ timeblock.ticket?.title }}
        </td>
        <td class="text-break" (click)="this.editTimeBlock(timeblock);">{{ timeblock.notes }}</td>
      </tr>
      <tr>
        <td colspan="4">
          <button class="fa fa-success btn-block" (click)="this.editTimeBlock(null);">New</button>
        </td>
      </tr>
    </tbody>
  </table>
  <div>Total Hours: {{this.totalHours}}</div>
  <a href="/timesheet/print?date={{this.datePipe.transform(this.currentDate, 'yyyy-MM-dd')}}&count=7">Print</a>
</div>