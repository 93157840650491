import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TimeBlock } from '../_models/timeBlock';
import { Observable } from 'rxjs';
import { TimeBlockForUpdate } from '../_models/timeBlockForUpdate';
import { TimeSheet } from '../_models/timesheet';
import { TimeBlockResults } from '../_models/timeBlockResults';

@Injectable({
  providedIn: 'root'
})
export class TimeBlockService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getTimeBlocks(startTime: any, endTime: any): Observable<TimeBlock[]> {
    return this.http.get<TimeBlock[]>(this.baseUrl + 'timesheet?start=' + startTime + '&end=' + endTime);
  }

  getTimeBlock(timeBlockId: number) {
    return this.http.get<TimeBlock>(this.baseUrl + 'timesheet/' + timeBlockId);
  }

  updateTimeBlock(timeBlock: TimeBlockForUpdate) {
    return this.http.put<TimeBlockResults>(this.baseUrl + 'timesheet', timeBlock);
  }

  deleteTimeBlock(timeBlockId: number) {
    return this.http.get(this.baseUrl + 'timesheet/delete/' + timeBlockId);
  }

  printTimeSheet(start: any, count: any): Observable<TimeSheet[]> {
    return this.http.get<TimeSheet[]>(this.baseUrl + 'timesheet/print?start=' + start + '&count=' + count);
  }
}
