import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { TicketListComponent } from './ticket-list/ticket-listcomponent';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { TicketListResolver } from './_resolvers/ticket-list.resolver';
import { ProjectListResolver } from './_resolvers/project-list.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { LoginComponent } from './authentication/login/login.component';
import { PasswordComponent } from './authentication/password/password.component';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { ProjectComponent } from './project/project.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { TimesheetPrintComponent } from './timesheet/timesheetPrint/timesheetPrint.component';
import { TimePrintResolver } from './_resolvers/time-print.resolver';

export const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            // tslint:disable-next-line: max-line-length
            { path: 'member/edit/:id', component: MemberEditComponent, resolve: { member: MemberEditResolver }, canDeactivate: [PreventUnsavedChanges] },
            { path: 'project', component: ProjectComponent, resolve: { member: ProjectListResolver } },
            { path: 'ticket-list', component: TicketListComponent, resolve: { member: TicketListResolver } },
            { path: 'timesheet', component: TimesheetComponent },
            { path: 'timesheet/print', component: TimesheetPrintComponent, resolve: { timesheets: TimePrintResolver} }
        ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'confirm/:key', component: ConfirmComponent },
    { path: 'reset/:key', component: PasswordComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
