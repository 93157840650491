import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project } from '../_models/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getMyProjects(active: boolean): Observable<Project[]> {
    return this.http.get<Project[]>(this.baseUrl + 'project/status/' + (active == true?'true':'false'));
  }

  getSubscriptionProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.baseUrl + 'project/subscription');
  }
}
