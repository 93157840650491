<div class="modal-header">
  <h4 class="modal-title">{{ this.modalTitle }}</h4>
  <button type="button" class="fa fa-close" aria-label="Close"
    (click)="cancel()">
 </button>  
</div>
<div class="modal-body">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
    <div>
      <label>
        Start:
        <timepicker formControlName="start" [minuteStep]="15"></timepicker>
      </label>
      <div *ngIf="myForm.get('start').invalid && (myForm.get('start').dirty || myForm.get('start').touched)">
        Please select a Start.
      </div>
    </div>
    <div>
      <label>
        End:
        <timepicker formControlName="end" [minuteStep]="15"></timepicker>
      </label>
      <div *ngIf="myForm.get('end').invalid && (myForm.get('end').dirty || myForm.get('end').touched)">
        Please select an End.
      </div>
    </div>
    <div>
      <label>
        Ticket:
        <select formControlName="ticket">
          <option *ngFor="let ticket of this.tickets" [value]="ticket.id">
            {{ticket.title}}
         </option>
        </select>
      </label>
      <div *ngIf="myForm.get('notes').invalid && (myForm.get('notes').dirty || myForm.get('notes').touched)">
        Messages must be at least 5 characters long.
      </div>
    </div>
    <div>
      <label>
        Notes:
        <input formControlName="notes" placeholder="">
      </label>
      <div *ngIf="myForm.get('notes').invalid && (myForm.get('notes').dirty || myForm.get('notes').touched)">
        Messages must be at least 5 characters long.
      </div>
    </div>
    <button type="button" (click)="this.cancel();">Cancel</button>
    <button type="submit" [disabled]="myForm.invalid">Save</button>
    <button *ngIf="this.editTimeblock.id > 0" type="button" (click)="this.delete();">Delete</button>
  </form>
  
</div>