import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ticket } from '../_models/ticket';
import { TicketForUpdate } from '../_models/ticketForUpdate';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getTickets(ticketStatus: boolean): Observable<Ticket[]> {
    console.log(this.baseUrl + 'tickets/status/' + (ticketStatus ? 'true' : 'false'));
    return this.http.get<Ticket[]>(this.baseUrl + 'tickets/status/' + (ticketStatus ? 'true' : 'false'));
  }

  updateTicket(ticket: TicketForUpdate): Observable<Ticket> {
    return this.http.put<Ticket>(this.baseUrl + 'tickets', ticket);
  }

  activateTicket(ticketId: number, value: boolean): Observable<Ticket> {
    console.log(ticketId);
    console.log(value);
    console.log(this.baseUrl + 'tickets');
    return this.http.put<Ticket>(this.baseUrl + 'tickets', { id: ticketId, isActive: value })
  }
}
