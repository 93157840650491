<div class="modal-header">
  <h4 class="modal-title">{{ this.modalTitle }}</h4>
  <button type="button" class="fa fa-close" aria-label="Close"
    (click)="cancel()">
 </button>  
</div>
<div class="modal-body">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
    <div>
      <label>
        Project:
        <select formControlName="project">
          <option *ngFor="let project of this.projects" [value]="project.id">
            {{project.title}} / {{project.code}}
         </option>
        </select>
      </label>
      <div *ngIf="myForm.get('project').invalid && (myForm.get('start').dirty || myForm.get('start').touched)">
        Please select a Project.
      </div>
    </div>
    <div>
      <label>
        Title:
        <input formControlName="title" placeholder="">
      </label>
      <div *ngIf="myForm.get('title').invalid && (myForm.get('title').dirty || myForm.get('title').touched)">
        Please enter a title.
      </div>
    </div>
    <div>
      <label>
        Link Number:
        <input formControlName="linkNumber" placeholder="">
      </label>
      <div *ngIf="myForm.get('linkNumber').invalid && (myForm.get('linkNumber').dirty || myForm.get('linkNumber').touched)">
        LInk Number is not valid
      </div>
    </div>
    <div>
      <label>
        Estimated Hours:
        <input formControlName="estimatedHours" placeholder="">
      </label>
      <div *ngIf="myForm.get('estimatedHours').invalid && (myForm.get('estimatedHours').dirty || myForm.get('estimatedHours').touched)">
        Estimated Hours is not valid
      </div>
    </div>
    <button type="button" (click)="this.cancel();">Cancel</button>
    <button type="submit" [disabled]="myForm.invalid">Save</button>
    <button *ngIf="this.editTicket.id > 0 && this.editTicket.isActive == true" type="button" (click)="this.activate(false);">Deactivate</button>
    <button *ngIf="this.editTicket.id > 0 && this.editTicket.isActive == false" type="button" (click)="this.activate(true);">Activate</button>
  </form>
  
</div>